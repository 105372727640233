import { memo, SVGProps } from 'react';

const Icon = (props: SVGProps<SVGSVGElement & { beta?: boolean }>) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      style={{ fill: '#C8C8C8' }}
      d="M10.01,18.9c-0.51,0.77,5.61,46.34,5.51,49.22c-0.1,2.89-3.48,10.45-5.77,17.11
	c-2.29,6.67-6.17,19.9-5.57,20.4s42.28,5.7,55.32,5.37s31.22-3.98,46.8-5.22c10.21-0.81,15.79-1.7,16.61-1.7s1.64-0.25,1.14-2.08
	c-0.5-1.83-6.03-18.1-8.21-24.07c-2.18-5.97-5-13.07-5-13.07s2.18-15.65,3.71-25.9c1.53-10.25,2.66-15.49,2.42-15.97
	c-0.24-0.48-36.79-5.89-37.52-5.89c-0.73,0-30.42,8.07-31.95,7.99C45.97,25,10.53,18.12,10.01,18.9z"
    />
    <path
      style={{ fill: '#1E85F9' }}
      d="M21.14,67.84c0,0-4.37-42.47-4.37-42.74s31.67,5.44,31.67,5.44l18.74,7.25
	c0,0,12.87-15.06,14.15-14.96c1.28,0.1,26.94,3.94,27.91,4.05c0.97,0.1,1.49,0.77,1.28,2.15c-0.2,1.38-5.85,36.48-5.85,36.48
	l-43.78,11.9L21.14,67.84z"
    />
    <polygon
      style={{ fill: '#136FD8' }}
      points="48.44,30.55 81.33,22.83 79.71,63.51 65.09,73.69 50.35,68.78 "
    />
    <path
      style={{ fill: '#36B4E2' }}
      d="M21.14,67.84l29.21,0.94l14.98,3.71l14.38-8.99c0,0,11.62,0.72,14.86,0.96
	c3.24,0.24,10.11,1.04,10.11,1.04s12.42,32.24,12.65,32.65s0,1.23-0.82,1.34s-10.39,0.58-10.39,0.58s-4.14-1.52-7.18-1.11
	c-3.03,0.41-7.76,2.92-7.76,2.92l-6.36,0.7c0,0-1.05-1.46-1.87-2.28c-0.82-0.82-2.16-1.4-2.16-1.4l-15.14-3.95l-19.13,10.11
	c0,0-33.26-3-34.59-3.21c-1.33-0.21-1.95-0.49-1.4-2.16C11.09,98.02,21.14,67.84,21.14,67.84z"
    />
    <path
      style={{ fill: '#6CCBF6' }}
      d="M50.35,68.78c0,0,5.76-0.4,14.69-1.79c9.9-1.54,14.68-3.34,14.68-3.34L81,103.32
	c0,0-9.41,1.98-16.88,2.09s-17.61-0.34-17.61-0.34L50.35,68.78z"
    />
    <path
      style={{ fill: '#C8C8C8' }}
      d="M48.44,30.55l0.87,17.5c0,0,0.7-1.64,1.89-1.94c1.2-0.3,3.39-0.7,3.54-1.44
	c0.15-0.75-0.55-2.89-0.35-3.89c0.2-1,1.1-1.69,1-3.69c-0.1-1.99-1-2.04-0.8-3.04c0.2-1,0.5-1.84,1.05-2.74
	c0.55-0.9,0.85-0.95,1.2-1.54c0.28-0.47,0.4-1.3,0.4-1.3L48.44,30.55z"
    />
    <path
      style={{ fill: '#C8C8C8' }}
      d="M61.22,32.81c-0.26,0.99-0.6,1.49,0.1,2.04c0.7,0.55,1.05,1.35,1.2,1.69
	c0.15,0.35,0.2,1.15,1.05,1.15s1.05-0.9,1.2-1.64c0.1-0.5,0.3-1.74,0.3-1.74s1.74-1.05,1.49-1.84c-0.25-0.8-1.25-0.9-2.44-0.75
	C62.89,31.86,61.52,31.66,61.22,32.81z"
    />
    <path
      style={{ fill: '#C8C8C8' }}
      d="M74.52,27.63c0.35-0.2,0.9-1.44,1.35-1.69c0.45-0.25,0.94-0.27,1.2,0.4c0.55,1.44,1.35,1.49,1.2,2.34
	s-1.84,0.65-2.24,0.95c-0.4,0.3-1.35,0.7-1.94,0.55c-0.6-0.15-2.34-1.1-1.74-2.04C72.76,27.44,74.52,27.63,74.52,27.63z"
    />
    <path
      style={{ fill: '#C8C8C8' }}
      d="M80.55,42.24c0,0-0.77,0.22-1.01,1.22c-0.24,1-0.34,1.17-0.34,1.17s-0.86,0.82-1.62,1.2
	c-0.76,0.38-1.37,0.93-1.37,0.93s-1.34-0.21-2.3,0.24c-0.96,0.45-1.31,1.17-1.31,1.17s1.44,6.32,1.44,6.22
	c0-0.1,6.24-3.01,6.24-3.01L80.55,42.24z"
    />
    <path
      style={{ fill: '#C8C8C8' }}
      d="M69.71,46.3c0,0-1.88-1.47-2.87-1.57c-0.6-0.06-2.6,0.51-2.97,1.04c-0.37,0.54-1.65,2.6-2.16,3.64
	c-0.51,1.04-2.45,2.43-2.26,3.57c0.26,1.54,1.69,1.21,1.69,1.21l1.38-0.03l0.61-2.97l3.4-3.4L69.71,46.3z"
    />
    <path
      style={{ fill: '#40C252' }}
      d="M53.96,68.38c0,0,0.46-1.03,1.39-1.59s4.16-2.62,4.77-2.62s1.39,0.72,1.75,1.13s0.62,0.82,0.92,0.87
	s2.82,0.05,4.31-0.15c1.49-0.21,2.87-0.87,2.87-1.44s-1.9-0.98-2.21-1.03c-0.31-0.05-1.69,0.21-1.85,0.31
	c-0.15,0.1-0.46,0.46-0.98,0.36c-0.51-0.1-1.39-0.87-1.75-1.39c-0.36-0.51-0.98-1.28-1.64-1.23c-0.67,0.05-1.44,0.26-1.8,0.51
	c-0.36,0.26-1.64,1.9-2.1,2.21c-0.46,0.31-1.69,0.15-2.1-0.62s-0.46-1.64-0.21-1.9c0.26-0.26,1.13-0.41,1.33-0.67
	c0.21-0.26-0.26-1.49,0.26-1.9c0.51-0.41,1.95,0,2.05-0.1c0.1-0.1,1.33-1.75,1.44-2.1c0.1-0.36,0.05-1.08,0.26-1.18
	c0.21-0.1,1.13-0.21,1.28,0s0.98,0.51,1.75,0.31s2.1-1.64,2.1-2.21s-0.62-0.67-0.77-1.13c-0.15-0.46,0.36-1.13,0.82-1.59
	c0.46-0.46,0.77-1.08,0.36-1.39c-0.41-0.31-0.82,0.05-1.28,0.56c-0.46,0.51-1.23,1.49-1.23,2s0.62,1.85,0.21,2.1
	s-0.92,0.87-1.23,0.67c-0.31-0.21-1.53-0.99-1.53-0.99s-0.16-0.86,0.5-1.93c0.67-1.08,2.62-4,4.05-5.03s3.53-1.29,3.53-1.29
	s0.89,0.37,1.24,0.73c0.36,0.36,0.77,1.39,0.41,1.8c-0.36,0.41-1.08,0.46-1.08,0.46s0.21,1.03,0.82,1.08
	c0.62,0.05,1.97-1.83,1.97-1.83s1.01-0.12,1.78,0.14c0.77,0.26,0.98,1.18,1.69,1.49c0.72,0.31,2.16,0.1,2.87,0.41
	c0.72,0.31,1.33,1.13,1.33,1.13l-0.56,12.32c0,0-6.6,2.09-12.68,3.08C61.13,67.7,53.96,68.38,53.96,68.38z"
    />
    <path
      style={{ fill: '#40C252' }}
      d="M56.85,53.68c-0.44,0.1-0.68,0.38-0.64,0.75c0.04,0.38-0.08,0.94-0.08,0.94s-0.98,0.6-0.79,1.28
	c0.19,0.68,0.71,0.68,1.28,1.05s1.16,0.49,1.54,0.26c0.38-0.23,1.03-1.03,0.68-1.65C58.31,55.37,58.35,53.34,56.85,53.68z"
    />
    <path
      style={{ fill: '#E0E0E0' }}
      d="M31.78,27.53c0,0-0.05,0.86-0.23,1.31c-0.18,0.45-1.03,0.95-0.99,2.21c0.05,1.27-0.23,2.21,0.21,2.69
	c0.69,0.76,0.7,2.42,0.7,2.42s-0.63,0.59-0.54,1.54c0.09,0.95,0.84,1.48,2.95,1.32c1.35-0.1,1.87-0.28,1.96-0.91
	c0.09-0.63,0.49-2.19,0.67-3.36c0.18-1.17,0.64-2.28,0.64-2.28s0.81-0.32,1.17-0.63c0.36-0.32,0.77-0.54,1.17-0.5
	c0.41,0.05,0.77,0.9,0.77,0.9s-0.75,0.94-1.56,1.66c-0.81,0.72-0.91,1.13-0.72,2.11c0.18,0.91,0.6,1.52,1.19,1.57
	c0.59,0.05,1.04-0.14,1.72,0c0.68,0.14,0.99,0.81,0.99,0.81s0.63,1.22,0.95,2.17c0.32,0.95,0.77,2.49,0.81,3.03
	c0.05,0.54-0.25,3.15-0.02,4.14c0.23,0.99,1.87,4.4,2.78,4.44c0.9,0.05,1.22-1.22,1.45-1.76s1.45-2.34,1.45-2.34l-0.87-17.5
	L31.78,27.53z"
    />
    <path
      style={{ fill: '#E0E0E0' }}
      d="M18.7,44.09c0,0,0.47-0.71,0.92-0.84s1.31-0.54,1.31-0.86s-0.13-1.14,0.09-1.94
	c0.23-0.81,1.72-1.76,3.84-1.77c1.62-0.01,2.35,1,2.66,1.55c0.32,0.54,0.45,2.3,0.59,2.58s0.63,0.81,1.17,0.81s0.72-0.23,0.72-0.63
	c0-0.41-0.23-0.72,0.05-1.31c0.27-0.59,0.68-1.17,2.21-1.36c1.54-0.18,3.27-0.37,3.8-0.23c0.9,0.25,2.49,1.76,2.76,2.08
	c0.27,0.32,0.54,1.58,0.63,2.12c0.09,0.54,0.95,1.49,1.22,1.81c0.27,0.32,0.36,2.53-1.4,2.62c-1.76,0.09-1.76-1.99-1.76-2.53
	c0-0.54-0.18-1.13-0.45-1.22c-0.27-0.09-1.42-0.17-1.54,0.09c-0.4,0.88,0.14,1.45-0.09,1.76c-0.23,0.32-3.48,3.12-3.66,3.66
	c-0.18,0.54-0.68,1.45-0.09,2.17c0.59,0.72,2.71,1.49,3.12,1.72c0.41,0.23,1.27-0.45,1.17-0.95c-0.09-0.5-0.59-1.04-0.59-1.63
	c0-0.59,0.77-1.67,1.22-1.76c0.45-0.09,1.63,1.04,1.63,1.04s1.81-0.14,2.26,0.18s0.54,1.36,0.54,1.36l0.59,0.99
	c0,0-2.53,4.29-2.67,4.34c-0.14,0.05-16.94-1.08-16.94-1.08l-2.98-9.35L18.7,44.09z"
    />
    <path
      style={{ fill: '#7ADC8A' }}
      d="M20.62,62.81c0,0,0.72,1.4,0.94,1.94c0.23,0.54,0.72,1.31,0.9,1.67c0.18,0.36,1.04,1.36,1.72,1.31
	s0.59-2.21,1.17-2.03c0.59,0.18,0.81,1.36,1.04,1.63c0.23,0.27,0.59,0.72,0.59,0.72l2.21,1.17l0.72-1.17c0,0,0-0.86,0.14-1.04
	c0.14-0.18,1.63-1.17,1.85-1.27c0.23-0.09,2.09,0.27,2.62,0.14c1.09-0.27,3.03-2.71,3.43-3.43c0.41-0.72,1.08-1.49,1.36-1.9
	c0.27-0.41,0.05-0.88,0.05-0.88s1.5,0.22,2.04-0.91c0.28-0.59,0.25-1.33,0.25-1.33s1.4-1.04,1.4-1.76c0-0.72-1.45-2.08-1.45-2.08
	s-1.27,0.86-2.39,1.49s-2.53,1.14-4.25,0.87c-1.72-0.27-2.25-0.51-2.57-0.78c-0.32-0.27-0.71-1.11-1.06-1.57
	c-0.76-0.98-2.14-1.37-2.37-2.09s1.17-1.67,0.59-2.67c-0.59-0.99-2.62-0.45-3.75-0.18c-1.13,0.27-2.67,0.77-2.94,0.68
	c-0.27-0.09-0.05-0.81-1.08-1.72c-0.91-0.8-2.99-2.46-2.99-2.46L20.62,62.81z"
    />
    <path
      style={{ fill: '#D0FE8B' }}
      d="M26.99,68.06h2.94c0,0,0.08,0.5,0.27,0.65c0.19,0.15,0.68,0.46,0.95,0.42
	c0.27-0.04,0.91-0.86,1.63-0.79c1.08,0.12,1.79,0.66,2.09,1.12c0.3,0.45,0.5,1.65,1.07,2.03c0.57,0.38,1.58-0.21,3.33,0
	c1.26,0.15,1.59,0.15,1.89,0.41s0.63,1,0.85,1.04c0.22,0.04,0.85-0.26,1.26-0.15c0.41,0.11,1.81,1.66,2.37,2.03
	c0.55,0.37,1.07,0.85,1.07,0.85s0.78-0.33,1.59,0.07c0.81,0.41,0.97,1.42,0.97,1.42s0.23,2.13-1.64,4.97
	c-2.19,3.33-2.96,4.44-3.51,5.07c-0.55,0.63-2,1.52-2.55,2.26c-0.55,0.74-0.85,1.26-0.81,1.59s0.07,1.22-0.18,1.78
	c-0.26,0.55-0.44,0.55-0.52,1.22s0.48,1.81,0.04,2.22c-0.44,0.41-1.92,0.18-2.74-1.26c-0.81-1.44-0.63-3.74-0.37-5.03
	c0.26-1.29,1.11-4.07,1.15-5.84c0.04-1.78-1-3.62-2.03-4.59s-1.33-1.29-1.33-2.92c0-1.11,0.59-1.63,0.59-1.63l0.11-1.59
	c0,0-1.19-0.22-1.59-0.74c-0.41-0.52-0.81-0.96-1.33-1.11c-0.52-0.15-1.71,0.17-2.66-0.27c-0.39-0.18-1.21-0.98-1.55-1.21
	C27.46,69.5,26.99,68.06,26.99,68.06z"
    />
    <path
      style={{ fill: '#E0E0E0' }}
      d="M36.27,104.16c0,0,0.32-0.64,0.96-0.96c0.64-0.32,1.55-0.68,1.59-0.96c0.04-0.28,0.64-2.07,1.44-2.07
	s0.56,1.04,0.6,1.4c0.04,0.36,0.4,0.64,0.32,1.24c-0.08,0.6-0.44,0.56-0.44,0.88s0.08,0.88,0.08,0.88L36.27,104.16z"
    />
    <path
      style={{ fill: '#E3FFAD' }}
      d="M53.96,68.38c0,0-0.81,0.84-0.81,1.33s-0.26,2.59,0.22,3.64c0.48,1.05,0.7,1.49,1.54,1.8
	c0.83,0.31,1.27,0.57,2.02,0.57s1.62,0.09,2.02,0.13s1.36,0.75,1.54,0.97c0.18,0.22,0.31,1.4,0.53,1.71s1.1,0.88,1.1,0.88
	s-0.4,2.41,0.09,3.56c0.48,1.14,0.88,2.77,1.19,3.56c0.31,0.79,1.49,1.89,1.84,2.15s1.14-0.18,1.71-0.7s1.89-2.06,2.2-2.37
	c0.31-0.31,0.31-1.19,0.61-1.58c0.31-0.4,0.04-1.19,0.48-1.71c0.44-0.53,1.4-1.27,1.49-1.84s-0.22-2.2,0.09-2.72
	c0.31-0.53,1.62-2.28,2.06-2.68c0.44-0.4,0.75-1.27,0.48-1.54s-1.98-0.18-2.46-0.48c-0.48-0.31-1.32-1.62-1.54-2.06
	s-1.01-1.49-1.14-1.93c-0.13-0.44,0.7-0.92,0.92-0.79c0.22,0.13,1.32,1.89,1.76,2.5c0.44,0.61,0.44,0.83,0.75,0.92
	c0.31,0.09,1.19,0.18,1.4,0c0.22-0.18,1.1-0.61,1.49-0.92c0.4-0.31,1.05-0.57,1.01-0.88s-0.18-1.01-0.4-1.1
	c-0.22-0.09-1.45,0.09-1.45,0.09s-1.19-1.4-1.27-1.67s0.57-0.61,1.01-0.31s1.45,0.79,1.71,0.88c0.26,0.09,1.49,0.44,1.89,0.44
	c0.4,0,1.93,0.92,1.93,0.92l-0.25-5.48c0,0-6.68,2.14-12.68,3.08C60.82,67.71,53.96,68.38,53.96,68.38z"
    />
    <path
      style={{ fill: '#E0E0E0' }}
      d="M53.33,105.28c0,0,2.33-1.47,2.75-1.71s3.02-1.36,3.86-1.59c0.48-0.13,5.27-0.43,6.14-0.41
	c1.12,0.02,2.13,0.35,3.35,0.29c1.22-0.06,4.41,0.31,5.02-0.06s3.49-2.56,4.1-2.82c0.75-0.31,2.31-0.04,2.31-0.04l0.14,4.37
	c0,0-9.51,1.98-16.88,2.09C56.76,105.53,53.33,105.28,53.33,105.28z"
    />
    <path
      style={{ fill: '#E0E0E0' }}
      d="M88.48,30.85c-1.04-0.56-1.47,1.12-1.63,1.55c-0.16,0.44-0.31,1.13-0.04,1.63
	c0.32,0.6,1.04,0.76,1.35,1.04s1.35,0.6,1.63-0.44c0.28-1.04-0.52-1.91-0.52-1.91S89.32,31.3,88.48,30.85z"
    />
    <path
      style={{ fill: '#E0E0E0' }}
      d="M80.55,42.25c0,0,0.58-0.16,0.79,0.24c0.12,0.22,0.14,0.41,0.28,0.56s1.79,0.28,1.95,0.04
	s1.39-2.47,2.71-3.27c1.32-0.8,2.87-1,3.55-1.32c0.68-0.32,0.76-1.35,1.55-1.35s3.11,1.55,3.27,1.83s-0.36,1.04-0.84,1.63
	c-0.48,0.6-1.24,1-0.8,1.28c0.44,0.28,1.99,0.52,2.87,0.56s1.39-0.28,2.07,0.04c0.68,0.32,0.4,1.75,0.8,1.83
	c0.4,0.08,2.47,0.24,3.15-0.16c0.68-0.4,0.52-1,1.24-1.16s1.43-0.24,2.11,0.32c0.68,0.56,1.35,1.16,1.71,1.47
	c0.36,0.32,0.96,0.44,0.96,0.44l-0.82,5.12c0,0-5.52,2.49-6.03,2.49c-0.52,0-20.92,1.03-20.92,1.03L80.55,42.25z"
    />
    <path
      style={{ fill: '#7ADC8A' }}
      d="M80.29,51.34c0,0,0.38,0.59,0.5,0.83c0.12,0.24,0.76,0.48,1.08,0.2s0.12-2.35,0.32-2.63
	c0.2-0.28,2.19-0.04,2.35-0.16c0.16-0.12,1.2-0.52,1.63-0.28c0.44,0.24,1.16,1.35,1.75,1.32c0.6-0.04,1.32,0.2,1.79-0.36
	c0.48-0.56,1.24-1.63,2.27-1.99s1.71-0.44,1.87-0.6s1.55-2.03,2.07-2.23c0.52-0.2,1.43-0.08,1.79,0c0.36,0.08,0.52,0.24,0.56,1.04
	c0.04,0.8,0.24,1.55,0.4,1.79s1.35,1.35,1.71,1.39c0.36,0.04,1.47-0.24,2.55,0c1.08,0.24,2.71,0.76,3.11,0.76s1.04-0.05,1.04-0.05
	l-0.48,2.98c0,0-0.29-0.19-1.01-0.07s-1.04,0.6-1.51,0.48s-1-0.28-1.32-0.16s-1.99,1.91-1.79,2.35c0.2,0.44,1.67,0.04,2.07,0.32
	s0.96,0.76,0.96,1.08s0,1.28-0.28,1.47c-0.28,0.2-1.55,0.16-1.83,0.36c-0.28,0.2,0,1.08-0.44,1.63c-0.44,0.56-1.87,1.79-1.87,1.79
	s-0.22,0.76-0.35,1.01C99,64.1,98.64,64.64,98.4,64.6c-0.24-0.04-1.32-1.51-1.83-1.55c-0.52-0.04-0.36,1.55-0.36,1.55l-16.49-0.94
	L80.29,51.34z"
    />
    <path
      style={{ fill: '#7ADC8A' }}
      d="M103.87,60.9c-0.04-0.22-0.91-0.37-1.11-0.07c-0.2,0.3-0.77,1.07-1.07,1.71s-1.01,2.01-0.5,2.31
	c0.29,0.17,1.55-0.72,2.06-1.52C103.37,63.14,104.04,61.91,103.87,60.9z"
    />
    <path
      style={{ fill: '#D0FE8B' }}
      d="M96.21,64.6l-16.48-0.95l0.25,5.48c0,0,0.57,0.7,0.8,1.4s0.77,2.05,1.14,2.62s0.44,1.61,1.31,1.51
	c0.87-0.1,0.81-1.21,1.07-1.95c0.27-0.74,0.94-1.41,1.21-1.64c0.27-0.23,0.84-0.87,1.11-0.77c0.27,0.1,0.71,0.94,1.07,1.41
	c0.6,0.77,2.31,1.51,2.31,1.51s2.44,0.73,2.78-0.34c0.3-0.94-0.32-2.17-0.1-2.58C92.96,69.8,94,69.78,95,69.5
	c1.31-0.37,1.68-1.38,1.81-2.48S96.21,64.6,96.21,64.6z"
    />
    <path
      style={{ fill: '#D0FE8B' }}
      d="M97.72,79.27c0.16-0.22-0.77-0.65-1.46-1.82s-0.98-1.85-1.32-1.92c-0.34-0.07-2.3,0.84-2.3,1.01
	s0.98,1.41,0.98,1.41s-1.34,0.36-1.99-0.62c-0.65-0.98-0.55-1.85-0.96-2.54c-0.41-0.69-1.05-1.68-1.44-1.65
	c-0.38,0.02-0.84,0.34-0.65,1.01s0.34,0.89,0.34,0.89c0.34,0.74-0.46,1.7-0.12,2.25c0.34,0.55,3.12,2.4,3.62,2.61
	S97.43,79.67,97.72,79.27z"
    />
    <path
      style={{ fill: '#D0FE8B' }}
      d="M97.39,75.53c0.74,0.08,0.91-1.7,0.77-2.4s-0.72-2.52-1.32-2.52c-0.6,0-1.13,0.74-1.01,1.22
	c0.12,0.48,0.53,1.2,0.55,1.44C96.4,73.52,96.71,75.46,97.39,75.53z"
    />
    <path
      style={{ fill: '#D0FE8B' }}
      d="M99.64,76.34c-0.14,0.09-0.24,1.13-0.1,1.25c0.14,0.12,1.15,0.24,1.63,0.86
	c0.48,0.62,0.5,1.29,0.81,1.39c0.31,0.1,2.88-0.48,2.88-1.63C104.86,77.59,100.07,76.06,99.64,76.34z"
    />
    <path
      style={{ fill: '#D0FE8B' }}
      d="M110.3,90.81c0.26-0.45,0.08-1.29,0.34-1.79c0.26-0.49,0.96-0.72,1.27-0.36
	c0.31,0.36,0.34,1.58,0.13,1.97c-0.14,0.26-0.16,1.16-0.88,1.79s-1.14,0.57-1.35,0.62c-0.21,0.05-1.19,0.88-1.6,0.34
	c-0.41-0.54,0.18-1.42,0.75-1.84C109.52,91.12,110.12,91.12,110.3,90.81z"
    />
    <path
      style={{ fill: '#D0FE8B' }}
      d="M101.84,82.06c-0.1-0.1-0.23-1.84-1.16-1.89c-0.93-0.05-1.68,0.72-1.68,0.72s-1.16-0.08-1.45,0.16
	c-0.28,0.23-1.35,1.37-1.81,1.58c-0.47,0.21-1.19,0.41-1.68,0.62c-0.49,0.21-0.78,0.96-0.72,1.76c0.05,0.8,0.23,2.59,0.31,3
	c0.08,0.41,0.62,1.5,1.94,0.98c1.32-0.52,1.79-1.16,2.02-1.19c0.23-0.03,2.33-0.03,2.46-0.1c0.13-0.08,1.32,2.07,2.36,2.3
	c1.04,0.23,2.33,0.13,2.56-0.05c0.23-0.18,1.6-2.07,1.73-2.74c0.13-0.67,0.1-2.07-0.16-2.61s-1.92-2.36-1.92-2.36
	s-0.26-1.97-1.48-1.99c-0.96-0.02-0.28,1.42-0.52,1.66C102.41,82.14,101.84,82.06,101.84,82.06z"
    />
  </svg>
);
const WTIcon = memo(Icon);
export default WTIcon;
